import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Container from "components/Container"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div
      style={{
        textAlign: "left",
        margin: "0 auto",
        maxWidth: "800px",
        padding: "20px",
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> [Date]
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to StreamAlign Enterprises LLC ("we", "our", "us"). We are
        committed to protecting and respecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information
        when you visit our website streamalign.io, specifically in relation to
        the use of LinkedIn intake forms.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        When you submit a LinkedIn intake form through our website, we may
        collect the following information:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Job title</li>
        <li>Company name</li>
        <li>LinkedIn profile information</li>
        <li>Other relevant information you choose to provide</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>
        We use the information collected from LinkedIn intake forms for the
        following purposes:
      </p>
      <ul>
        <li>To contact you regarding your inquiry or application</li>
        <li>
          To provide you with information, products, or services you request
          from us
        </li>
        <li>To improve our website and services</li>
        <li>To send you marketing communications, where permitted by law</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties except as described below:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who assist us in operating our website,
          conducting our business, or servicing you.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information
          if required to do so by law or in response to valid requests by public
          authorities.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal data against unauthorized access, disclosure,
        alteration, or destruction.
      </p>

      <h2>6. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and obtain a copy of your personal data</li>
        <li>Rectify any inaccurate or incomplete personal data</li>
        <li>Erase your personal data in certain circumstances</li>
        <li>
          Restrict the processing of your personal data in certain circumstances
        </li>
        <li>
          Object to the processing of your personal data in certain
          circumstances
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at
        howard@streamalign.io.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated effective date. Please review this
        policy periodically for any updates.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <p>
        StreamAlign Enterprises LLC
        <br />
        howard@streamalign.io
        <br />
      </p>
    </div>
  </Layout>
)

export default PrivacyPolicy
